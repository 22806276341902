.handle {
    width: 75px;
    height: 75px;
    background-color: #4172cc;
    color: #FFFFFF;
    border: 1px solid #4172cc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.286rem;
    font-weight: 700;
    position: absolute;
}

.close-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 8px;
    border: 2px solid #FFFFFF;
    width: 15px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}

.store_bound {
    width: 100%;
    height: 90vh;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0);
}
.create_rack {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 7px;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: -4px;
    background-color: #198754;
    border-color: #198754;
    color: #FFFFFF;
}

.store_rack {
    height: 100%;
    overflow-y: scroll;
}

.c-grab {
    cursor: grab;
}